import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { IHttpClient } from '@wix/yoshi-flow-editor';
import { SocialGroupsWeb } from '@wix/ambassador-social-groups-web/http';
import { queryGroups } from '@wix/ambassador-social-groups-v2-group/http';
import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

import { IStore } from '../types';

export const fetchGroup = createAsyncThunk(
  'group:fetch',
  function (groupId: string, thunkAPI) {
    const { application } = thunkAPI.getState() as IStore;

    const service = SocialGroupsWeb(
      '/_api/social-groups/',
    ).SocialGroupsService()({
      Authorization: application.instance,
    });

    return service.getGroup({ groupId });
  },
);

export const determine = createAsyncThunk(
  'group:determine',
  async function (_, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;

    const {
      data: { groups },
    } = await httpClient.request(
      queryGroups({
        query: {
          filter: {
            privacyLevel: PrivacyStatus.PUBLIC,
          },
        },
      }),
    );

    const group = groups?.[0];

    return group?.id;
  },
);
